.pac-container {
  /* overflow: hidden; */

  /* custom */
  left: 20px !important;
  z-index: 1;
  margin-top: 25px;
  box-shadow: none;
  border-top: none;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  width: 100%;

}

.pac-logo:after {
  /* content: "";
  padding: 1px 1px 1px 0;
  height: 16px;
  display: block;
  background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
  background-repeat: no-repeat;
  background-size: 120px 14px; */
  background-position: left;
  margin-top: 10vh;



}



.pac-item {


  /* custom */
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  padding: 10px 0px;
  color: rgba(0, 0, 0, 0.54);
  border-top: none;


}

.pac-item:hover {
  background-color: #ffffff;
}

.pac-item-selected,
.pac-item-selected:hover {
  background-color: #ffffff;
}

.pac-matched {
  font-weight: 500;
  color: #d81b60;
}

.pac-item-query {

  /* custom */
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  display: block;


}

.pac-icon {
  width: 18px;
  height: 24px;

  background-size: 35px;
  float: left;
  margin-right: 16px;
  vertical-align: middle;
  margin-top: 8px;
  margin-bottom: 8px;



}
