@import "flags.css";
@import "gauto.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
}


/* .mat-subheader {
  color: #344955 !important;
} */

.primary {
  color: #d81b60;
}

.right-spacer {
  flex: 1 1 auto;
}

.form {
  min-width: 150px;
  max-width: 600px;
}

.form-field-width {
  width: 100%;
}

.form-field-width1 {
  width: 50%;
}


.content {
  flex: 1;
  /* background-color: #f5f5f5; */

}


.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f2f3f5;
}


.mute {
  color: rgba(0, 0, 0, 0.54);
}



.mat-card-content>:last-child app-label {
  margin-bottom: 0;
}

/* .mat-card-content>:last-child p {
  margin-bottom: 0;
} */

div app-label:nth-of-type(odd) {
  /* margin-right: 8px; */
  width: calc(50% - 8px);
}

div app-label:nth-of-type(even) {
  /* margin-left: 8px; */
  width: calc(50% - 8px);
}


.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  z-index: 99;
}


.base {
  margin-top: 4px;
  /* margin-bottom: 4px; */
}

/* .mat-nav-list-item,
.mat-list-item {
  background-color: white;
} */

.mat-ripple {
  z-index: 9;
}

.mat-h2,
.mat-title,
.mat-typography h2 {
  margin: 8px 0 8px;
  font-size: 16px;
}




.green {
  color: green;
}

.red {
  color: red;
}


mat-form-field.mat-form-field {
  font-size: 12px;
}

.box-height {
  height: 206px;
}


.t {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  width: 98px;
  margin-top: 4px;

}

.viewport::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.cdk-virtual-scroll-data-source .viewport {
  min-height: 166px;
}

.cdk-virtual-scroll-data-source .viewport .cdk-virtual-scroll-content-wrapper {
  display: flex;
  flex-direction: row;
}

.cdk-virtual-scroll-data-source .item {
  height: 100%;
  width: 98px;
  margin-left: 8px;
  margin-right: 8px;
  outline: 0;
}


.cdk-virtual-scroll-content-wrapper>:first-child {
  margin-left: 16px;
}

.cdk-virtual-scroll-content-wrapper>:last-child {
  margin-right: 16px;
}



.my-dialog .mat-dialog-container {
  padding: 0;
  background: none;
  box-shadow: none;
}


.code {
  background: #fffbed;
  padding: 4px;
  color: #878787;
  font-size: 10px;
  border-radius: 4px;
}

.code1 {
  background: #ffcccb;
  padding: 4px 8px;
  border-radius: 4px;
}


.spacing {
  margin-right: 16px;
  margin-left: 16px;
  border-radius: 8px;
}

.spacing-list {
  margin-top: 8px;
  margin-bottom: 8px;
}

.spacing>:first-child .spacing-list {
  margin-top: 0px;
}

.spacing>:last-child .spacing-list {
  margin-bottom: 0px;
}



.mat-list-item,
.mat-nav-list-item {
  background-color: #ffffff;

}

.mat-toolbar {
  background-color: #ffffff;
  height: 56px;
  font-size: 14px;
}

.top {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -o-sticky;
  position: -ms-sticky;
  position: sticky;
  top: 0;
  z-index: 99;
}

.bottom {

  position: -webkit-sticky !important;
  position: -moz-sticky !important;
  position: -o-sticky !important;
  position: -ms-sticky !important;
  position: sticky;
  bottom: 0;
  z-index: 99;
}


.mat-list-base[dense] .mat-list-item .mat-list-text>h4,
.mat-list-base[dense] .mat-list-option .mat-list-text>h4 {
  font-size: 13px !important;
}

.dark {
  background: #344955;
  color: white;
}

.cdk-virtual-scroll-data-source-category .viewport {
  min-height: 48px;
}

.cdk-virtual-scroll-data-source-category .viewport .cdk-virtual-scroll-content-wrapper {
  display: flex;
  flex-direction: row;
}


.cdk-virtual-scroll-data-source-category .item {
  /* margin: 12px 16px; */
  /* outline: 0; */
  /* border: 1px solid red; */

  font-size: 12px;
  font-weight: 500;

  height: 48px;
  padding: 0 24px;
  cursor: pointer;
  box-sizing: border-box;
  opacity: .7;
  display: inline-flex;
  /* justify-content: center; */
  align-items: center;
  white-space: nowrap;
  vertical-align: top;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}

.cdk-virtual-scroll-data-source-category .active {
  /* margin: 12px 16px; */
  /* outline: 0; */
  /* border: 1px solid red; */

  opacity: 1;
  /* border-bottom: 1px solid #d81b60; */
  color: #d81b60;


}

@media (min-width: 600px) {
  .cdk-virtual-scroll-data-source-category .item {
    min-width: 160px;
  }
}
